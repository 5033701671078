import { useState, useEffect } from 'react';
import './App.css';
import { Resolution, usePDF } from 'react-to-pdf';
import {parse} from 'csv-parse/browser/esm'

const BATCH_SIZE = 100

const CARD_PADDING_IN_MM = 3
const A4_SIZE_IN_MM = {
  WIDTH: 210,
  HEIGHT: 297,
}
const CARDS_PER_PAGE_COL = 5

const CARD_HEIGHT_IN_MM = (A4_SIZE_IN_MM.HEIGHT - ((CARDS_PER_PAGE_COL * 2) * CARD_PADDING_IN_MM)) / CARDS_PER_PAGE_COL

const colorMap = {
  'CIAM': '#3277a8',
  'Content': '#32a85a',
  'DEV': '#ead452',
  'CJ': '#c26c1b',
  'BIA': '#9332a8',
  'UX': '#c21b45',
  'Frontend': '#fc17f5',
}

const Tag = ({
  title
}) => {
  return (
    <div className='rounded-full bg-slate-400 text-white font-bold px-2 py-1'>
      <div className='display-block text-xs'>{title}</div>
    </div>
  )
}

const Card = ({
  id,
  title,
  tags,
  parent,
  storyPoints,
}) => {
  const excludedTags = ['PI5', 'DNP', ...Object.keys(colorMap)]

  var colors = tags.map((tag) => {
    return colorMap[tag] ? {tag, color: colorMap[tag]} : undefined
  }).filter(v => !!v)

  const tagsToShow = tags.filter((tag) => !excludedTags.includes(tag))

  return (
    <div className={`border rounded-md overflow-hidden h-[210] flex`} style={{height: `${CARD_HEIGHT_IN_MM}mm`}}>
      <div className="flex-1 flex flex-row">
        <div className='self-stretch flex flex-row'>
          {colors.map(({tag, color}) => (
            <div key={color} className="relative w-[24px] h-full" style={{backgroundColor: color}}>
              <span className='origin-left -rotate-90 text-xs block uppercase font-bold text-white absolute left-[50%]' style={{
                top: 'calc(100% - 16px)'
              }}>{tag}</span>
            </div>
          ))}
        </div>
        <div className='flex-1 flex flex-col p-2'>
          <div className="flex-1 flex-col">
            {parent && <p className="font-xs font-bold" style={{
              color: parent.work_item_type === 'Feature' ? '#7115b3' : '#ed7424'
            }}>{parent.title.replace('Portals - DNP - ', '')}</p>}
            <p>{id}</p>
            <p className='font-bold'>{title}</p>
          </div>
          <div className="flex flex-row gap-1 items-end">
            <div className='flex flex-1 gap-2 flex-wrap'>
              {tagsToShow.map((tag) => <Tag key={tag} title={tag}/>)}
            </div>
            <div className="flex flex-row gap-1">
              <div className="w-8 h-8 border rounded-md text-center">
                <span className="leading-8 font-bold">{storyPoints}</span>
              </div>
              <div className="w-8 h-8 border rounded-md"></div>
              <div className="w-8 h-8 border rounded-md"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function App() {
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf', method: 'save'});

  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)

  const [inputCsvStories, setInputCsvStories] = useState(null)
  const [outputJsonStories, setOutputJsonStories] = useState(null)

  const [inputCsvParents, setInputCsvParents] = useState(null)
  const [outputJsonParents, setOutputJsonParents] = useState(null)

  const totalBatches = outputJsonStories && Math.ceil(outputJsonStories.length / BATCH_SIZE)

  const handleUpload = (event, type) => {
    const file = event.target.files[0]

    const fileReader = new FileReader()

    fileReader.onload = function(fileLoadedEvent){
      const textFromFileLoaded = fileLoadedEvent.target.result
      if (type === 'story') {
        setInputCsvStories(textFromFileLoaded)
      } else {
        setInputCsvParents(textFromFileLoaded)
      }
    }

    fileReader.readAsText(file, 'UTF-8')
  }

  useEffect(() => {
    processCsv(inputCsvStories, 'story')
  }, [inputCsvStories])

  useEffect(() => {
    processCsv(inputCsvParents, 'parent')
  }, [inputCsvParents])

  const processCsv = (data, type) => {
    if (!data) {
      return
    }

    parse(data, (err, data) => {
      if (!err) {
        const output = []

        let [labels, ...rows] = data
        labels = labels.map((label) => label.toLowerCase().replaceAll(' ', '_'));

        for (const row of rows) {
          let outputRow = {}

          for (let i = 0; i <= labels.length; i++) {
            outputRow[labels[i]] = row[i]
          }

          output.push(outputRow)
        }

        console.log(output)

        if (type == 'story') {
          setOutputJsonStories(output.map((item) => ({...item, tags: item.tags.split('; ')})))
        } else {
          setOutputJsonParents(output)
        }
        return
      }
      alert('Something is wrong with the input file.')
    })
  }

  if (!inputCsvStories || !inputCsvParents) {
    return (
      <div>
        <label htmlFor="data-source-stories">Stories</label>
        <input type="file" name="data-source-stories" id="data-source-stories" onChange={(e) => handleUpload(e, 'story')}/>
        <label htmlFor="data-source-parents">Parents</label>
        <input type="file" name="data-source-parents" id="data-source-parents" onChange={(e) => handleUpload(e, 'parent')}/>
      </div>
    )
  }

  if (!outputJsonStories || !outputJsonParents || totalBatches === undefined) {
    return (
      <div>
        <p>processing...</p>
      </div>
    )
  }

  return (
    <div>
      <button onClick={() => toPDF()}>Download PDF</button>
      {new Array(totalBatches).fill(null).map((_, i) => (
        <button key={'button' + i} onClick={() => setCurrentBatchIndex(i)}>set page {i} ({i === currentBatchIndex ? 'active' : 'inactive'})</button>
      ))}
      <div ref={targetRef} style={{width: `${A4_SIZE_IN_MM.WIDTH}mm`}}>
        <div className="grid grid-cols-2" style={{padding: `${CARD_PADDING_IN_MM}mm`, gap: `${CARD_PADDING_IN_MM * 2}mm`}}>
          {outputJsonStories.filter((_, i) => (i >= (currentBatchIndex * BATCH_SIZE)) && (i < ((currentBatchIndex + 1) * BATCH_SIZE))).map((item, index) => (
            <Card
              key={item.id}
              id={item.id}
              title={item.title}
              storyPoints={item.story_points}
              parent={outputJsonParents.find((parent) => parent.id === item.parent)}
              tags={item.tags}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default App;
